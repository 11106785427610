<template>
  <div class="calculation">
    <ArticleText :title="title" :desc="desc" :article="sports"></ArticleText>

    <form>
      <Calculation
        v-for="list in formList"
        :key="list.label"
        :list="list"
        v-model.number="$data[list.data]"
      ></Calculation>
    </form>

    <h2>計算結果</h2>
    <table class="table table-striped">
      <tr>
        <td>P/PA</td>
        <td>{{ ppa }}</td>
      </tr>
    </table>

    <h2>P/PA（Pitches per Plate Appearance）</h2>
    <p>
      P/PAは、野球における重要な統計指標で、打者が1打席あたりに受ける平均投球数を示します。この指標は、打者の観察力や選球眼を測る上での重要な要素であり、戦術的なプレーにおいても大きな影響を与えます。
    </p>
    <p>
      P/PAの計算式は単純で、合計投球数を打席数で割ることで得られます。例えば、1つの試合で打者が20投球を見て、5回の打席があった場合、P/PAは20
      / 5 =
      4となります。この数値が高いほど、打者はより多くの投球を見ることができ、それによりピッチャーに対して有利な状況を作り出すことが期待されます。
    </p>
    <p>
      打者が多くの投球を見ることは、相手投手を疲労させたり、投球パターンを読み解るための時間を稼ぐ効果があります。これにより、後続の打者に有利な状況を作り出すことができ、攻撃の幅を広げることができます。
    </p>
    <p>
      チーム全体のP/PAの傾向を分析することで、対戦相手の投手陣に対するアプローチを見直すことができます。例えば、相手投手が速球よりも変化球を多く投げる傾向があれば、打者はそれに対応するための戦術を練ることが求められます。
    </p>
    <p>
      野球においてP/PAは単なる統計数値だけでなく、戦術や戦略の観点からも考慮されるべき重要な要素となっています。各打者のP/PAを理解し、戦術的に活用することで、チームはより戦略的で効果的なプレーを展開することができます。
    </p>
  </div>
</template>

<script>
import Calculation from "../../../components/Calculation.vue";
import getTitle from "@/getTitle";
import ArticleText from "../../../components/ArticleText.vue";
import articles from "@/mixins/articles.js";

export default {
  components: {
    Calculation,
    ArticleText,
  },
  mixins: [getTitle, articles],
  data() {
    return {
      num1: 0,
      num2: 0,
      formList: [
        { label: "投球数", data: "num1" },
        { label: "打席数", data: "num2" },
      ],
    };
  },
  computed: {
    ppa: function () {
      let ppa = this.num1 / this.num2;
      return this.isNan(ppa, 2);
    },
  },
};
</script>
