<template>
  <div class="calculation">
    <ArticleText :title="title" :desc="desc" :article="health">
      <p>
        子供の場合は<router-link to="/health/bmi">BMI</router-link
        >で計算してもあまり参考にならないので、ローレル指数を用いて肥満度を計ります。
      </p>
    </ArticleText>

    <form>
      <Calculation
        v-for="list in formList"
        :key="list.label"
        :list="list"
        v-model.number="$data[list.data]"
      ></Calculation>
    </form>

    <CalculationSelect
      :resultList="resultList"
      :calculationResult="laurel"
      labelName="ローレル指数"
    ></CalculationSelect>

    <h2>子供の肥満とローレル指数について</h2>

    <p>
      子供の肥満は、成長期において健康上の問題や将来的なリスクを引き起こす可能性がある重要な健康課題です。肥満の程度を評価するための指標として、ローレル指数（Lorenz
      Curve）が使用されることがあります。ローレル指数は、身体の脂肪の分布を考慮した肥満指数の一つであり、子供の成長において特に重要な役割を果たします。
    </p>

    <h3>ローレル指数の概要</h3>
    <p>
      ローレル指数は、身体の脂肪の分布や身体組成を考慮して肥満を評価するために開発されました。通常のBMI（Body
      Mass
      Index）は単に体重と身長の比率を示すものであるため、体重の増加が筋肉量の増加や成長によるものであるか、脂肪の増加によるものであるかを区別できません。一方で、ローレル指数は脂肪の蓄積の程度をより詳細に捉え、より正確な肥満度の評価を提供します。
    </p>

    <h3>子供の肥満の評価</h3>
    <p>
      子供の肥満は、発達段階や性別によって異なる成長パターンを示すため、従来のBMIだけでは十分な評価が難しい場合があります。子供の肥満の評価において、ローレル指数は成長に合わせた調整が行われ、より正確な評価を可能にします。
    </p>

    <p>
      ローレル指数は、身長と体重だけでなく、腹部の脂肪蓄積やその他の成長パラメータも考慮しています。これにより、子供の身体組成や成長段階に基づいた肥満の程度を示し、従来のBMIと比較してより個別化された情報を提供します。
    </p>

    <h3>ローレル指数の計算方法</h3>
    <p>
      ローレル指数の計算方法は複雑であり、通常は専門家や研究者によって行われます。計算には身長、体重、腹囲などの多くの要因が含まれ、それらのデータを基に算出される複合的な指数となります。計算には標準値や成長曲線も使用され、子供の成長段階に合わせた評価が可能となります。
    </p>

    <h3>ローレル指数の利用</h3>
    <p>
      ローレル指数は、主に研究や臨床の分野で使用され、子供の肥満に関する研究や診断において貢献しています。特に成長期の子供の体組成や肥満の影響をより深く理解し、個々の子供に対して適切な健康管理や介入を提供する上で重要なツールとなっています。
    </p>

    <h3>肥満と健康リスク</h3>
    <p>
      子供の肥満は将来的な健康リスクと関連しており、糖尿病、高血圧、心血管疾患などの疾患の発症リスクが増加します。また、肥満は心理的な健康にも影響を与え、自尊心や対人関係に悪影響を及ぼす可能性があります。
    </p>

    <p>
      ローレル指数を用いた肥満の評価は、これらのリスクをより具体的に評価し、個別に適した健康管理や予防策を提供するために重要です。しかしながら、単体での指標としてだけでなく、他の健康
      指標や臨床的な評価と組み合わせて使用することが、より包括的なアプローチとなります。
    </p>

    <h3>総括</h3>
    <p>
      ローレル指数は子供の肥満をより繊細に評価するための有用な指標であり、成長段階や性別に基づいた肥満の程度を詳細に示します。これにより、個別の成長パターンやリスクを考慮した健康管理が可能となり、将来的な健康リスクの予防や早期介入に寄与します。
    </p>

    <p>
      ただし、ローレル指数は通常、研究や専門の医療機関で使用されるものであり、一般の健康管理や日常的な評価においては、一般的な肥満指数や他の健康評価指標も併用されることが一般的です。
    </p>
  </div>
</template>

<script>
import Calculation from "@/components/Calculation.vue";
import CalculationSelect from "@/components/CalculationSelect.vue";
import getTitle from "@/getTitle";
import ArticleText from "@/components/ArticleText.vue";
import articles from "@/mixins/articles.js";

export default {
  components: {
    Calculation,
    ArticleText,
    CalculationSelect,
  },
  mixins: [getTitle, articles],
  data() {
    return {
      num1: 0,
      num2: 0,
      num3: 0,
      formList: [
        { label: "体重kg", data: "num1" },
        { label: "身長cm", data: "num2" },
      ],
      resultList: [
        { label: "ローレル指数" },
        { label: "100未満", text: "やせている" },
        { label: "100以上 115未満", text: "やせぎみ" },
        { label: "115以上 145未満", text: "普通" },
        { label: "145以上 160未満", text: "肥満ぎみ" },
        { label: "160以上	", text: "肥満" },
      ],
    };
  },
  computed: {
    laurel: function () {
      let laurel = (this.num1 / Math.pow(this.num2, 3)) * 10000000;

      if (isNaN(laurel) || laurel == "Infinity") {
        return 0;
      } else {
        if (laurel <= 99) {
          this.allWhite();
          this.isActiveSelector(2);
        } else if (laurel >= 100 && laurel <= 114) {
          this.allWhite();
          this.isActiveSelector(3);
        } else if (laurel >= 115 && laurel <= 144) {
          this.allWhite();
          this.isActiveSelector(4);
        } else if (laurel >= 145 && laurel <= 159) {
          this.allWhite();
          this.isActiveSelector(5);
        } else if (laurel >= 160) {
          this.allWhite();
          this.isActiveSelector(6);
        }

        return laurel.toFixed(1);
      }
    },
  },
};
</script>
