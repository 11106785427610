<template>
  <div class="calculation">
    <ArticleText :title="title" :desc="desc" :article="sports"></ArticleText>

    <form>
      <Calculation
        v-for="list in formList"
        :key="list.label"
        :list="list"
        v-model.number="$data[list.data]"
      ></Calculation>
    </form>

    <h2>計算結果</h2>
    <table class="table table-striped">
      <tr>
        <td>防御率</td>
        <td>{{ defense }}</td>
      </tr>
    </table>

    <h2>防御率（Earned Run Average, ERA）について</h2>
    <p>
      防御率（ERA）は、野球においてピッチャーの投球能力を評価するための主要な統計指標であり、そのピッチャーが相手打者に対してどれだけ有効な投球をしているかを示します。ERAは投手陣の安定性や効果を測る上で非常に重要な指標となっています。
    </p>
    <h3>防御率の計算式</h3>
    <p>
      防御率は、投手が許した自責点を投球回数で割り、それを9で掛けたものです。計算式は以下の通りです。
    </p>
    <p style="text-align: center">防御率 (ERA) = (自責点 ÷ 投球回数) × 9</p>
    <p>
      例えば、投手が100イニングで25自責点を許した場合、防御率は (25 ÷ 100) × 9 =
      2.25
      となります。これは平均して9イニングあたり2.25点の自責点を許していることを示しています。
    </p>
    <h3>防御率の解釈</h3>
    <p>
      防御率が低いほど、ピッチャーは相手打者に対して効果的なピッチングを行っていることを示します。一般的に、2.00未満の防御率は優れたピッチャーを示し、3.00以下であれば非常に優れた投手とされます。しかし、防御率は球場やリーグの平均得点なども影響を受けるため、単純な比較だけでなく状況を考慮することが重要です。
    </p>
    <h3>ERAの利用</h3>
    <p>
      ERAは投手だけでなく、投手陣全体やチームの守備力を測るのにも利用されます。優れたピッチャーは相手打者を得点圏に進ませず、自責点を最小限に抑えることが期待されます。また、クローザーやリリーフ投手のERAは特に重視され、短いイニングでの安定性が評価されます。
    </p>
    <p>
      野球において、ERAはピッチャーの投球能力を客観的かつ効果的に評価するために欠かせない指標であり、投手陣の柱となる選手の評価において大きな役割を果たしています。
    </p>
  </div>
</template>

<script>
import Calculation from "../../../components/Calculation.vue";
import getTitle from "@/getTitle";
import ArticleText from "../../../components/ArticleText.vue";
import articles from "@/mixins/articles.js";

export default {
  components: {
    Calculation,
    ArticleText,
  },
  mixins: [getTitle, articles],
  data() {
    return {
      num1: 0,
      num2: 0,
      formList: [
        { label: "自責点", data: "num1" },
        { label: "投球回数", data: "num2" },
      ],
    };
  },
  computed: {
    defense: function () {
      let defense = (this.num1 * 9) / this.num2;
      return this.isNan(defense, 2);
    },
  },
};
</script>
