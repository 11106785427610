<template>
  <div class="calculation">
    <ArticleText :title="title" :desc="desc" :article="date"></ArticleText>

    <input type="date" v-model="num1" />

    <h2>計算結果</h2>
    <table class="table table-striped">
      <tr>
        <td>小学校入学</td>
        <td>{{ age[0] }}年4月</td>
      </tr>
      <tr>
        <td>中学校入学</td>
        <td>{{ age[1] }}年4月</td>
      </tr>
      <tr>
        <td>高校入学</td>
        <td>{{ age[2] }}年4月</td>
      </tr>
      <tr>
        <td>大学入学</td>
        <td>{{ age[3] }}年4月</td>
      </tr>
    </table>

    <h2>誕生日から入学年度を自動計算する方法についての詳細な解説</h2>

    <p>
      誕生日から入学年度を自動計算することは、教育機関や組織において重要な情報を得る手段となります。この計算は、一般的に日本の学校や多くの国の学校で使用されています。以下に、誕生日から入学年度を計算する方法とその背景について詳細に説明します。
    </p>

    <h3>1. 入学年度とは</h3>

    <p>
      入学年度は、学校や大学において学生が初めてその学校に入学する年度を指します。この年度は通常、新学年が始まる4月1日から3月31日までの期間を指し、学年末の3月末をもって一つの学年が終了し、新しい学年が始まる仕組みです。従って、入学年度を知ることは学生の学年やクラスの特定に役立ちます。
    </p>

    <h3>2. 誕生日から入学年度を計算する方法</h3>

    <p>
      誕生日から入学年度を計算するためには、以下のステップを追うことが一般的です：
    </p>

    <ol>
      <li><strong>生年月日の取得：</strong> 対象者の生年月日を取得します。</li>
      <li>
        <strong>学年開始日と終了日の設定：</strong>
        学年の開始日（通常は4月1日）と終了日（3月31日）を設定します。
      </li>
      <li><strong>年齢計算：</strong> 生年月日から現在の年齢を計算します。</li>
      <li>
        <strong>入学年度の計算：</strong>
        年齢から入学年度を計算します。通常、入学年度は生年月日の年から現在の年を引いたものに、学年の境界を考慮して調整されます。
      </li>
    </ol>

    <p>
      この計算方法によって、対象者の入学年度を比較的簡単に導き出すことができます。
    </p>

    <h3>3. 入学年度計算の背景</h3>

    <p>
      日本の学校制度では、新学年が4月に始まります。これは、日本の会計年度が4月から翌年3月までを対象としているためです。この制度に基づいて、学校の入学年度も4月1日から3月31日までの期間を対象としています。このような学年制度は、他の国々でも採用されています。
    </p>

    <p>
      この制度に従って入学年度を計算することで、学生のクラスや学年を正確に特定し、教育機関や組織が効果的な組織運営を行う上での手助けとなります。
    </p>

    <h3>4. プログラムを用いた自動計算</h3>

    <p>
      入学年度を手動で計算する代わりに、プログラミングを使用して自動的に計算することも可能です。特にデータベースやウェブアプリケーションを構築する際には、このようなプログラムが便利です。例えば、JavaScriptやPythonなどのプログラミング言語を使用して、生年月日から入学年度を計算するスクリプトを作成できます。
    </p>

    <h3>まとめ</h3>

    <p>
      誕生日から入学年度を計算することは、学校や組織において重要な情報を得る手段です。この計算は、日本の学校制度や多くの国の学校で採用されており、入学年度の正確な把握は学生の管理や教育の効率を向上させます。手動で計算する方法やプログラムを利用して自動的に計算する方法を選択することで、より効率的に入学年度を取得できます。
    </p>

    <p>
      もし他に質問があればお知らせください。お手伝いできることがありましたら幸いです。
    </p>
  </div>
</template>

<script>
import getTitle from "@/getTitle";
import ArticleText from "@/components/ArticleText.vue";
import articles from "@/mixins/articles.js";

export default {
  components: {
    ArticleText,
  },
  mixins: [getTitle, articles],
  data() {
    return {
      num1: "",
      formList: [{ label: "誕生日", data: "num1" }],
    };
  },
  computed: {
    age: function () {
      let date = new Date(this.num1);
      let month = date.getMonth() + 1;
      let primarySchool = date.getFullYear() + 7;
      let juniorHighSchool = date.getFullYear() + 13;
      let highSchool = date.getFullYear() + 16;
      let University = date.getFullYear() + 19;

      if (month == 1 || month == 2 || month == 3) {
        primarySchool -= 1;
        juniorHighSchool -= 1;
        highSchool -= 1;
        University -= 1;
      }

      console.log(date);

      if (isNaN(primarySchool)) {
        return "";
      } else {
        return [primarySchool, juniorHighSchool, highSchool, University];
      }
    },
  },
};
</script>
