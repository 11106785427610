<template>
  <div class="calculation">
    <ArticleText :title="title" :desc="desc" :article="date"></ArticleText>

    <input type="date" v-model="num1" />

    <h2>計算結果</h2>
    <table class="table table-striped">
      <tr>
        <td>年齢</td>
        <td>{{ age }}歳</td>
      </tr>
    </table>

    <h2>年齢に関する包括的な解説</h2>

    <p>
      年齢は人間の成長や経過した時間を表す重要な指標であり、個人や社会においてさまざまな文脈で意味を持っています。本記事では、年齢に関する概念、生理学的、心理学的な側面、さらには異なる文化や社会での年齢観について総合的に解説します。
    </p>

    <h3>1. 年齢の定義</h3>

    <p>
      年齢は基本的に、生まれてからの経過した時間を示すものです。一般的には生年月日から現在の日付までの経過した年数として計算されます。また、年齢は生物学的、社会学的な観点からも捉えられ、個人の発達や社会的な役割の変化を示す指標となります。
    </p>

    <h3>2. 生理学的な年齢</h3>

    <p>
      生理学的な年齢は、個人の身体的な状態や機能に基づいて評価されます。これは主に生物学的な変化や健康状態を考慮し、実際の年齢とは異なることがあります。例えば、若々しい外見や健康な生活習慣がある場合、実際の年齢よりも若く見られることがあります。
    </p>

    <h3>3. 心理学的な年齢</h3>

    <p>
      心理学的な年齢は主に精神的な成熟や認知の発達に焦点を当てます。個人の経験、知識、感情の豊かさなどが心理学的な年齢に影響を与えます。例えば、同じ年齢でも経験が豊富な人は、他の人よりも心理学的に成熟していると見なされることがあります。
    </p>

    <h3>4. 年齢の文化的な側面</h3>

    <p>
      年齢に対する考え方や価値観は文化によって異なります。一部の文化では年齢を尊重し、長寿を祝福する一方で、他の文化では若さや新しさを重視することがあります。また、異なる文化では年齢に対する期待や社会的な役割が異なります。
    </p>

    <h3>5. 社会的な年齢</h3>

    <p>
      社会的な年齢は、個人が社会でどのような役割を果たすかや、その立場においてどのように見なされるかに関連します。例えば、学生、親、職業人など、異なる社会的な役割が年齢と結びついています。
    </p>

    <h3>6. 年齢の変遷</h3>

    <p>
      個人の年齢は成長、発展、変化のプロセスです。幼児期から思春期、成人期、中年期、高齢期といった段階を経て、年齢によって様々な生活の局面が訪れます。各段階での課題や経験が年齢を通じて形成されます。
    </p>

    <h3>7. 年齢と健康</h3>

    <p>
      年齢は健康にも大きな影響を与えます。加齢に伴い身体的な変化や病気のリスクが増加することがあります。一方で、健康的な生活習慣や医療の進歩により、高齢者でも健康で活動的な生活を送ることができるようになっています。
    </p>

    <h3>まとめ</h3>

    <p>
      年齢は多面的で複雑な概念であり、個人の成長や社会的な位置づけ、健康などに深く関わります。生理学的な側面から心理学的、文化的、社会的な視点まで、年齢は個人や社会のさまざまな側面に影響を与えます。また、個人が年齢をどのように捉え、経験するかも、その人自身の価値観や環境によって異なります。
    </p>

    <p>
      以上の解説が年齢に関する包括的な理解を提供する一助となれば幸いです。何か他に質問があればお気軽にお知らせください。
    </p>
  </div>
</template>

<script>
import getTitle from "@/getTitle";
import ArticleText from "@/components/ArticleText.vue";
import articles from "@/mixins/articles.js";

export default {
  components: {
    ArticleText,
  },
  mixins: [getTitle, articles],
  data() {
    return {
      num1: "",
      formList: [{ label: "誕生日", data: "num1" }],
    };
  },
  computed: {
    age: function () {
      let birthdayStr = this.num1.replace("-", "").replace("-", "");

      if (isNaN(birthdayStr) || birthdayStr.length !== 8) {
        return "";
      }
      let d = new Date();
      let dStr =
        "" +
        d.getFullYear() +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        ("0" + d.getDate()).slice(-2);
      return Math.floor((parseInt(dStr) - parseInt(birthdayStr)) / 10000);
    },
  },
};
</script>
