<template>
  <div class="calculation">
    <ArticleText :title="title" :desc="desc" :article="sports"></ArticleText>

    <form>
      <Calculation
        v-for="list in formList"
        :key="list.label"
        :list="list"
        v-model.number="$data[list.data]"
      ></Calculation>
    </form>

    <h2>計算結果</h2>
    <table class="table table-striped">
      <tr>
        <td>守備率</td>
        <td>{{ defensive }}</td>
      </tr>
    </table>
    <h2>守備率（Fielding Percentage）について</h2>
    <p>
      守備率は、野球においてチームや選手の守備の成功度合いを評価するための統計指標の一つです。この指標は、守備で成功した機会に対する成功率を示し、選手やチームの守備力を客観的に評価するのに役立ちます。
    </p>
    <h3>守備率の計算式</h3>
    <p>
      守備率は、守備での成功したプレーの回数を守備できるチャンスの総数で割ったものです。計算式は以下の通りです。
    </p>
    <p style="text-align: center">
      守備率 = 守備できるチャンスの総数 / (守備できるチャンスの総数 +
      失敗したプレーの回数)
    </p>
    <p>
      例えば、ある選手が100回の守備で成功が80回で、20回で失敗した場合、その選手の守備率は80
      / (80 + 20) = 0.8、つまり80%となります。
    </p>
    <h3>守備率の意味</h3>
    <p>
      守備率が高いほど、選手やチームは成功率が高い守備を行っていると言えます。失敗が少ないことは、相手チームの得点を阻止する上で重要であり、守備できるチャンスを逃さないことが守備力の指標となります。
    </p>
    <h3>守備率と守備の評価</h3>
    <p>
      守備率は一般的にエラーの発生回数を元に計算されるため、エラーが守備率に与える影響は大きいです。一方で、守備できるチャンスが限られるポジションや状況も考慮する必要があります。たとえば、内野手がホームランを阻止する機会は限られているため、守備率だけでその選手を評価するのは難しい場合があります。
    </p>
    <h3>守備率の発展的な利用</h3>
    <p>
      近年では、守備率の発展的な利用として、守備位置ごとのプレー成功率や守備範囲を測るための高度な分析が行われています。これにより、選手の守備特性や守備力の向上ポイントを特定し、戦術的な守備戦略の立案に活かすことが可能となっています。
    </p>
    <p>
      野球において、守備率は選手やチームの守備力を総合的に評価するための重要な指標であり、守備陣の信頼性や安定性を示すのに一役買っています。
    </p>
  </div>
</template>

<script>
import Calculation from "@/components/Calculation.vue";
import getTitle from "@/getTitle";
import ArticleText from "@/components/ArticleText.vue";
import articles from "@/mixins/articles.js";

export default {
  components: {
    Calculation,
    ArticleText,
  },
  mixins: [getTitle, articles],
  data() {
    return {
      num1: 0,
      num2: 0,
      num3: 0,
      formList: [
        { label: "刺殺", data: "num1" },
        { label: "補殺", data: "num2" },
        { label: "失策", data: "num3" },
      ],
    };
  },
  computed: {
    defensive: function () {
      let defensive =
        (this.num1 + this.num2) / (this.num1 + this.num2 + this.num3);
      return this.isNan(defensive, 2);
    },
  },
};
</script>
