<template>
  <div class="calculation">
    <ArticleText :title="title" :desc="desc" :article="sports"></ArticleText>

    <form>
      <Calculation
        v-for="list in formList"
        :key="list.label"
        :list="list"
        v-model.number="$data[list.data]"
      ></Calculation>
    </form>

    <CalculationSelect
      :resultList="resultList"
      :calculationResult="whip"
      labelName="WHIP"
    ></CalculationSelect>

    <h2>WHIP（Walks plus Hits per Inning Pitched）について</h2>
    <p>
      WHIP（ウィップ）は、野球におけるピッチャーの投球能力を評価するための統計指標の一つです。WHIPはピッチャーが与える被打者への走者数を示し、その投手がどれだけランナーを進ませるかを表します。具体的には、イニングあたりの与四死球数と被安打数を合計し、それを投球イニング数で割った値を示します。
    </p>
    <p>WHIPの計算式は以下の通りです。</p>

    <p>
      例えば、投手が100イニングで50四死球を与え、100安打を許した場合、そのWHIPは
      \( \frac{{ 50 + 100 }}{{ 100 }} = 1.5 \) となります。
    </p>
    <p>
      一般的に、低いWHIPが高い投球能力を示します。なぜなら、走者を進ませないことが相手打者に対して有利であり、守備側にとっても得点を減らす効果が期待されるからです。ピッチャーが四死球を減らし、被安打数も制御することで、相手打者の得点機会を減少させることができます。
    </p>
    <p>
      WHIPは投手だけでなく、投手陣全体の評価やチームの防御力を理解するためにも使用されます。特にリリーフ投手において、短いイニングでの効果的な投球が求められるため、WHIPはその投手の信頼性や安定性を示す指標となります。
    </p>
    <p>
      この指標は投手の制球力や制球技術を測る上で非常に有益であり、投手の評価や比較に広く活用されています。投手が効果的に走者を進ませず、被打率を抑えることができるかどうかを把握する上で、WHIPは重要な情報を提供します。
    </p>
  </div>
</template>

<script>
import Calculation from "../../../components/Calculation.vue";
import CalculationSelect from "@/components/CalculationSelect.vue";
import getTitle from "@/getTitle";
import ArticleText from "../../../components/ArticleText.vue";
import articles from "@/mixins/articles.js";

export default {
  components: {
    Calculation,
    ArticleText,
    CalculationSelect,
  },
  mixins: [getTitle, articles],
  data() {
    return {
      num1: 0,
      num2: 0,
      num3: 0,
      formList: [
        { label: "与四球数", data: "num1" },
        { label: "被安打数", data: "num2" },
        { label: "投球回数", data: "num3" },
      ],
      resultList: [
        { label: "WHIP" },
        { label: 1, text: "素晴らしい" },
        { label: 1.1, text: "非常に良い" },
        { label: 1.25, text: "平均以上" },
        { label: 1.32, text: "平均" },
        { label: 1.4, text: "平均以下" },
        { label: 1.5, text: "悪い" },
        { label: 1.6, text: "非常に悪い" },
      ],
    };
  },
  computed: {
    whip: function () {
      let whip = (this.num1 + this.num2) / this.num3;

      if (isNaN(whip) || whip == "Infinity") {
        return 0;
      } else {
        if (whip <= 1) {
          this.allWhite();
          this.isActiveSelector(2);
        } else if (whip >= 1 && whip <= 1.1) {
          this.allWhite();
          this.isActiveSelector(3);
        } else if (whip >= 1.1 && whip <= 1.25) {
          this.allWhite();
          this.isActiveSelector(4);
        } else if (whip >= 1.25 && whip <= 1.32) {
          this.allWhite();
          this.isActiveSelector(5);
        } else if (whip >= 1.32 && whip <= 1.4) {
          this.allWhite();
          this.isActiveSelector(6);
        } else if (whip >= 1.4 && whip <= 1.5) {
          this.allWhite();
          this.isActiveSelector(7);
        } else {
          this.allWhite();
          this.isActiveSelector(8);
        }

        return whip.toFixed(2);
      }
    },
  },
};
</script>
