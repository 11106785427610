<template>
  <div class="calculation">
    <ArticleText :title="title" :desc="desc" :article="health">
      <p>
        体脂肪量の自動計算ツールは<router-link to="/health/fat-mass"
          >こちら</router-link
        >
      </p>
    </ArticleText>

    <form>
      <Calculation
        v-for="list in formList"
        :key="list.label"
        :list="list"
        :step="list.step"
        :initialValue="list.value"
        v-model.number="$data[list.data]"
      ></Calculation>
    </form>

    <h2>計算結果</h2>
    <table class="table table-striped">
      <tr>
        <td>体脂肪量</td>
        <td>{{ lbm }}</td>
      </tr>
    </table>

    <h2>除脂肪体重（LBM）についての詳細な説明</h2>

    <p>
      除脂肪体重（Lean Body
      Mass、LBM）は、身体組成の一部であり、体脂肪を除いた全体の体重を指します。LBMは骨、筋肉、内臓器官、皮膚、血液、その他の非脂肪組織から構成されています。この指標は、身体の健康状態やフィットネスレベル、トレーニングの進捗を理解し、評価するための有益な情報を提供します。
    </p>

    <h3>LBMの構成要素</h3>

    <p>
      LBMは複数の構成要素から成り立っています。主な要素には以下が含まれます：
    </p>

    <ol>
      <li>
        <strong>筋肉：</strong>
        骨に接する筋肉や内臓器官周りの筋肉がLBMの大部分を占めています。
      </li>
      <li><strong>骨：</strong> 骨は体重の一部を構成し、LBMに寄与します。</li>
      <li>
        <strong>内臓器官：</strong>
        内臓器官は心臓、肺、腎臓などを含み、これらもLBMに含まれます。
      </li>
      <li><strong>皮膚：</strong> 体表面の組織もLBMに影響を与えます。</li>
      <li>
        <strong>血液：</strong> 血液も非脂肪組織であり、LBMにカウントされます。
      </li>
    </ol>

    <p>
      これらの要素は個々の人によって異なり、遺伝、性別、年齢、生活習慣、栄養状態などの要因によって変動します。LBMは通常、高いフィットネスレベルや強度を示す指標とされ、一般的には体脂肪が少ないことが期待されます。
    </p>

    <h3>LBMの測定方法</h3>

    <p>
      LBMを測定するためには、いくつかの方法があります。一部の方法は非侵襲的であり、他の方法は専門的な設備が必要です。主な測定方法には以下があります：
    </p>

    <ol>
      <li>
        <strong>デュアルエネルギーX線吸収法（DEXA）：</strong>
        X線を使用して骨密度や体組成を非常に精密に測定します。
      </li>
      <li>
        <strong>生体インピーダンス法：</strong>
        電気の伝導度を測定し、脂肪と非脂肪組織を区別して推定します。
      </li>
      <li>
        <strong>ボディファットスケール：</strong>
        生体インピーダンス法と同様の原理を使用し、非侵襲的な手軽な方法でLBMを評価します。
      </li>
      <li>
        <strong>ハイドロスタティックウェighing：</strong>
        水中での体重測定を通じて体脂肪率とLBMを推定します。
      </li>
    </ol>

    <p>
      これらの測定方法は、非侵襲的でありながらも一定の制約や誤差が存在します。精密な評価が必要な場合は、DEXAなどの専門的な手法が選択されることがあります。
    </p>

    <h3>LBMの重要性</h3>

    <p>
      LBMは健康とフィットネスにおいて重要な指標であり、いくつかの側面でその重要性が現れます：
    </p>

    <ol>
      <li>
        <strong>メタボリズム：</strong>
        LBMは基礎代謝率（BMR）を影響し、エネルギーの消費量に関与します。
      </li>
      <li>
        <strong>フィットネス：</strong>
        高いLBMは強度、パワー、耐久力などの側面でフィットネスを示唆します。
      </li>
      <li>
        <strong>老化の影響：</strong>
        年齢とともにLBMは減少する傾向があり、これが骨折リスクや身体機能の低下に寄与する可能性があります。
      </li>
      <li>
        <strong>体重管理：</strong>
        LBMの維持は、体重の減少時に筋肉減少を最小限に抑えるのに役立ちます。
      </li>
      <li>
        <strong>慢性病の予防：</strong>
        適切なLBMは慢性疾患の予防に寄与し、一般的な健康状態を改善することが期待されます。
      </li>
    </ol>

    <p>これらの側面は、LBMが健康状態や生活の質に与える影響を示しています。</p>

    <h3>LBMの向上</h3>

    <p>LBMを向上させるためには、適切なトレーニングと栄養が不可欠です：</p>

    <ol>
      <li>
        <strong>筋力トレーニング：</strong>

        筋肉を増加させ、LBMを向上させるために重要です。
      </li>
      <li>
        <strong>タンパク質摂取：</strong>
        適切なタンパク質の摂取は、筋肉の合成をサポートし、LBMの
        向上に寄与します。
      </li>
      <li>
        <strong>バランスの取れた食事：</strong>
        全体的な栄養バランスがLBMの維持や増加に重要です。適切なビタミン、ミネラル、脂質も含めた栄養摂取が必要です。
      </li>
      <li>
        <strong>十分な休息：</strong>
        筋肉の成長や修復は休息時に行われるため、十分な睡眠や適切なリカバリーがLBMの向上に寄与します。
      </li>
    </ol>

    <p>
      これらの健康的な生活習慣は、LBMの向上に寄与するだけでなく、全体的な健康を促進する効果があります。
    </p>

    <h3>LBMの制限と注意事項</h3>

    <p>LBMにはいくつかの制限と注意事項があります：</p>

    <ul>
      <li>
        <strong>個体差：</strong>
        人々の体格や生活習慣によってLBMの基準は異なります。一般的な指標として利用されることがありますが、個々の特徴や目標に合わせた評価が必要です。
      </li>
      <li>
        <strong>測定の誤差：</strong>
        測定方法には誤差が存在し、異なる方法で測定した場合に結果が異なることがあります。
      </li>
      <li>
        <strong>健康状態：</strong>
        特定の病気や状態はLBMに影響を与える可能性があります。例えば、慢性の疾患や栄養不良はLBMの低下につながることがあります。
      </li>
    </ul>

    <p>
      これらの要因を考慮して、LBMは一つの指標であり、全体的な身体組成や健康評価には他の指標と併用することが重要です。
    </p>

    <h3>まとめ</h3>

    <p>
      除脂肪体重（LBM）は体脂肪を除いた全体の体重であり、身体組成や健康評価において重要な指標です。LBMの理解は、適切なトレーニングや栄養、健康状態の管理に役立ちます。ただし、LBMだけでなく他の指標との総合的な評価が必要であり、個々の健康目標に合わせたアプローチが重要です。医療専門家やトレーニングのプロフェッショナルの助言を得ながら、総合的な健康管理を目指しましょう。
    </p>
  </div>
</template>

<script>
import Calculation from "@/components/Calculation.vue";
import getTitle from "@/getTitle";
import ArticleText from "@/components/ArticleText.vue";
import articles from "@/mixins/articles.js";

export default {
  components: {
    Calculation,
    ArticleText,
  },
  mixins: [getTitle, articles],
  data() {
    return {
      num1: 60,
      num2: 12,
      formList: [
        { label: "体重kg", data: "num1", step: "0.1" },
        { label: "体脂肪量kg", data: "num2", step: "0.1" },
      ],
    };
  },
  computed: {
    lbm: function () {
      let lbm = this.num1 - this.num2;
      return this.isNan(lbm, 1);
    },
  },
};
</script>
