<template>
  <div class="calculation">
    <ArticleText :title="title" :desc="desc" :article="sports"></ArticleText>

    <form>
      <Calculation
        v-for="list in formList"
        :key="list.label"
        :list="list"
        v-model.number="$data[list.data]"
      ></Calculation>
    </form>

    <h2>計算結果</h2>
    <p>{{ points }}</p>

    <h2>サッカーの勝ち点について</h2>
    <p>
      サッカーにおいて、勝ち点は試合結果に応じて与えられる得点であり、リーグ戦や大会などで順位を決定するための基本的な指標です。勝ち点はチームの成績を数値化し、勝敗や引き分けに対する評価を反映しています。一般的に、勝ち点が高いほどチームはリーグテーブルで上位に位置し、優勝や上位進出に近づくことが期待されます。
    </p>
    <h3>勝ち点の割り当て</h3>
    <p>サッカーの勝ち点の割り当ては通常以下のようになります。</p>
    <ul>
      <li>勝利：3勝ち点</li>
      <li>引き分け：1勝ち点</li>
      <li>敗北：0勝ち点</li>
    </ul>
    <p>
      このシステムにより、試合結果に応じてチームに与えられる勝ち点が異なります。勝利した場合は3勝ち点が加算され、引き分けた場合は1勝ち点が加算されます。敗北した場合は勝ち点が加算されません。この方式により、試合の結果が直接勝ち点に反映され、チームの総得点となります。
    </p>
    <h3>勝ち点の役割</h3>
    <p>
      勝ち点はリーグ戦や大会においてチームの順位を決定する上で重要な役割を果たします。得失点差が同じ場合、勝ち点が上位の決定要因となります。また、優勝争いや降格争いなどで順位が争われる場合、勝ち点は勝敗や引き分けによって直接影響を受けるため、戦術やチーム戦略の立案においても大きな要素となります。
    </p>
    <h3>連勝ボーナスやペナルティ</h3>
    <p>
      一部の大会やリーグでは、連勝ボーナスや連敗ペナルティといった制度が導入されていることがあります。連勝ボーナスは、チームが複数連勝した場合に追加の勝ち点を与えるもので、連敗ペナルティは逆に複数連敗した場合に減少させるものです。これにより、連勝や連敗が勝ち点に与える影響が大きくなり、チームの安定性や成績の変動が顕著になります。
    </p>
    <h3>国内リーグと国際大会での勝ち点</h3>
    <p>
      国内リーグと国際大会では、勝ち点の割り当てが異なることがあります。国内リーグでの勝ち点は上述の通り3勝ち点、1勝ち点、0勝ち点が一般的ですが、国際大会では異なるルールが適用されることがあります。国際大会では引き分けても直接PK戦に進む場合や、勝利した場合でも得点差やアウェイゴールのルールが適用されることがあり、それによって勝ち点の計算
    </p>
  </div>
</template>

<script>
import Calculation from "../../../components/Calculation.vue";
import getTitle from "@/getTitle";
import ArticleText from "../../../components/ArticleText.vue";
import articles from "@/mixins/articles.js";

export default {
  components: {
    Calculation,
    ArticleText,
  },
  mixins: [getTitle, articles],
  data() {
    return {
      num1: 0,
      num2: 0,
      num3: 0,
      formList: [
        { label: "勝ち数", data: "num1" },
        { label: "引き分け数", data: "num2" },
        { label: "負け", data: "num3" },
      ],
    };
  },
  computed: {
    points: function () {
      return this.num1 * 3 + this.num2;
    },
  },
};
</script>
