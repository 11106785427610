<template>
  <div class="calculation">
    <ArticleText :title="title" :desc="desc" :article="health">
      <p>
        体脂肪量を少なくして、スマートで筋肉質なかっこいい体になりたいですね。
      </p>
    </ArticleText>

    <form>
      <Calculation
        v-for="list in formList"
        :key="list.label"
        :list="list"
        :step="list.step"
        :initialValue="list.value"
        v-model.number="$data[list.data]"
      ></Calculation>
    </form>

    <h2>計算結果</h2>
    <table class="table table-striped">
      <tr>
        <td>体脂肪量</td>
        <td>{{ fatmas }}</td>
      </tr>
    </table>

    <h2>体脂肪量についての詳細な説明</h2>

    <p>
      体脂肪量は、体内に存在する脂肪の総量を指します。正常な範囲内の適切な体脂肪量は、健康を維持し、機能をサポートするために重要です。一方で、過剰な体脂肪量は様々な健康問題を引き起こす可能性があります。本稿では、体脂肪量の概要、測定方法、健康への影響、および体脂肪の管理について詳細に探ります。
    </p>

    <h3>体脂肪量の概要</h3>

    <p>
      体脂肪は、エネルギー貯蔵、体温調節、内臓器官の保護、ホルモンの制御など、様々な生理的機能を果たしています。適切な量の体脂肪は健康にとって必要不可欠であり、性別、年齢、個々の生活習慣によって異なります。通常、男性は女性よりも少ない体脂肪を持ち、成人になるにつれて体脂肪量は増加する傾向があります。
    </p>

    <p>体脂肪は二つの主要なタイプに分かれます：</p>

    <ol>
      <li>
        <strong>皮下脂肪：</strong>
        皮膚の下に位置し、身体の形状や外見に影響を与えます。
      </li>
      <li>
        <strong>内臓脂肪：</strong>
        内臓器官の周りに蓄積し、メタボリックシンドロームや心血管疾患などのリスクを増加させる可能性があります。
      </li>
    </ol>

    <p>
      これらの脂肪の適切なバランスが健康を維持するために重要であり、特に内臓脂肪が過剰になると様々な健康問題に繋がる可能性があります。
    </p>

    <h3>体脂肪量の測定方法</h3>

    <p>
      体脂肪量を正確に測定するには、いくつかの方法があります。以下は主な測定方法の一部です。
    </p>

    <ol>
      <li>
        <strong>皮下脂肪の厚さ測定：</strong>
        肌褶皺を測定することで、皮下脂肪量を推定します。
      </li>
      <li>
        <strong>デュアルエネルギーX線吸収法（DEXA）：</strong>
        X線を使用して骨密度や脂肪量を精密に測定します。
      </li>
      <li>
        <strong>生体インピーダンス法：</strong>
        体内の電気伝導度を測定し、体脂肪率を推定します。
      </li>
      <li>
        <strong>空気浮揚法：</strong> 体積を測定して体脂肪率を推定します。
      </li>
      <li>
        <strong>ハイドロスタティックウェighing（水中での体重測定）：</strong>
        水の中で体重を測定して体脂肪率を推定します。
      </li>
    </ol>

    <p>
      これらの方法はそれぞれ異なる利点と制約があり、専門の機器や設備が必要なことがあります。最も適切な方法は、個々の健康状態や利用可能なリソースによって異なります。
    </p>

    <h3>健康への影響</h3>

    <p>
      体脂肪量が適切な範囲を超えると、様々な健康問題が発生する可能性があります。これらの影響は個人の健康状態や生活習慣によって異なりますが、一般的な健康への影響には以下が含まれます：
    </p>

    <ol>
      <li>
        <strong>心血管疾患：</strong>
        内臓脂肪の蓄積は心血管疾患のリスクを増加させる可能性があります。
      </li>
      <li>
        <strong>2型糖尿病：</strong>
        過剰な体脂肪がインスリン抵抗性を引き起こし、2型糖尿病の発症リスクが増加します。
      </li>
      <li>
        <strong>関節の負担：</strong>
        過剰な体重により、関節に対する負担が増加し、関節炎や関節の損傷のリスクが高まります。
      </li>
      <li>
        <strong>呼吸器疾患：</strong>
        肥満が喘息や睡眠時無呼吸症候群などの呼吸器疾患のリスクを増加させる可能性があります。
      </li>
      <li>
        <strong>がん：</strong> 一部のがんの発症リスクが肥満と関連しています。
      </li>
    </ol>

    <p>
      過剰な体脂肪がこれらの疾患のリスクを増加させる主なメカニズムは、炎症の増加、ホルモンの不均衡、代謝の変化などが関与しています。
    </p>

    <h3>体脂肪の管理</h3>

    <p>
      適切な体脂肪量を維持または達成するためには、以下のようなアプローチが効果的です：
    </p>

    <ol>
      <li>
        <strong>バランスの取れた食事：</strong>
        栄養バランスの取れた食事は、適切な体重と体脂肪量をサポートします。
      </li>
      <li>
        <strong>適度な運動：</strong>
        有酸素運動と筋力トレーニングの組み合わせが、体脂肪を減少させ、筋肉を増加させるのに役立ちます。
      </li>
      <li>
        <strong>ストレス管理：</strong>
        長期間のストレスは食欲を増加させ、体脂肪の蓄積に寄与する可能性があります。
      </li>
      <li>
        <strong>十分な睡眠：</strong>
        睡眠不足はホルモンバランスを乱し、体脂肪の蓄積を促進する可能性があります。
      </li>
    </ol>

    <p>
      これらの健康的な生活習慣の維持は、体脂肪量を管理し、健康を促進するのに役立ちます。ただし、個々の健康状態や目標に応じて、医療専門家のアドバイスを受けることも重要です。
    </p>

    <h3>総括</h3>

    <p>
      体脂肪量は健康において重要な要素であり、適切な管理は健康の維持と様々な疾患の予防に寄与します。正確な測定方法を選択し、健康的な生活習慣を維持することが、体脂肪の適切な管理に不可欠です。一般的な健康指標として体脂肪量を理解し、適切なアプローチで管理することは、健康的で充実した生活を実現するために重要です。
    </p>
  </div>
</template>

<script>
import Calculation from "@/components/Calculation.vue";
import getTitle from "@/getTitle";
import ArticleText from "@/components/ArticleText.vue";
import articles from "@/mixins/articles.js";

export default {
  components: {
    Calculation,
    ArticleText,
  },
  mixins: [getTitle, articles],
  data() {
    return {
      num1: 0,
      num2: 0,
      formList: [
        { label: "体重kg", data: "num1", step: "0.1" },
        { label: "体脂肪率", data: "num2", step: "0.1" },
      ],
    };
  },
  computed: {
    fatmas: function () {
      let fatmas = this.num1 * (this.num2 / 100);
      return this.isNan(fatmas, 1);
    },
  },
};
</script>
