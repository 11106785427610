<template>
  <div class="calculation">
    <ArticleText :title="title" :desc="desc" :article="money"></ArticleText>

    <form>
      <Calculation
        v-for="list in formList"
        :key="list.label"
        :list="list"
        :step="list.step"
        :initialValue="list.value"
        v-model.number="$data[list.data]"
      ></Calculation>
    </form>

    <h2>計算結果</h2>
    <table class="table table-striped">
      <tr>
        <td>税</td>
        <td>{{ tax }}円</td>
      </tr>
      <tr>
        <td>税込</td>
        <td>{{ total }}円</td>
      </tr>
    </table>

    <h2>消費税についての総合的な解説</h2>

    <p>
      消費税は、商品やサービスの購入にかかる一種の税金であり、国や地域の経済体制において広く採用されています。この税制は、政府の歳入を確保し、公共サービスやプログラムの資金を提供するために使用されます。以下では、消費税の基本的な仕組み、種類、国際的な比較、影響などについて総合的に解説します。
    </p>

    <h3>消費税の基本的な仕組み</h3>

    <p>
      消費税は、商品やサービスの購入額に課される税金であり、通常は製造から最終消費者までの商品やサービスの供給チェーンにおいて段階的に課税されます。基本的な仕組みは以下の通りです：
    </p>

    <ol>
      <li>
        <strong>製造段階での課税：</strong>
        商品が製造または生産される段階で一定の税率がかけられます。
      </li>
      <li>
        <strong>流通段階での課税：</strong>
        商品が卸売業者や小売業者によって仕入れられ、これにも税金がかかります。
      </li>
      <li>
        <strong>最終消費者段階での課税：</strong>
        商品が最終的に消費者に販売される際にも、最終価格に一定の税率が付加されます。
      </li>
    </ol>

    <p>
      このように、商品やサービスが供給チェーンを経るたびに税金が蓄積され、最終的に消費者がそれを支払います。この仕組みは、広範な経済活動において均等に課税を行うことが可能であるとされています。
    </p>

    <h3>消費税の種類</h3>

    <p>
      消費税にはいくつかの異なるタイプがあります。主なものには以下が含まれます：
    </p>

    <ol>
      <li>
        <strong>付加価値税（VAT）：</strong>
        多くの国で使用される方式で、商品やサービスの価値の増加分に課税される。製造や流通段階での税金が最終価格に含まれる。
      </li>
      <li>
        <strong>売上税：</strong>
        一部の国で見られる方式で、商品が売却されたときに直接課税される。
      </li>
      <li>
        <strong>特別消費税：</strong>
        特定の商品やサービスに対して特別な税率が設定される。たとえば、アルコールやたばこに課されることがある。
      </li>
    </ol>

    <p>
      これらの税制は地域によって異なり、国ごとに独自の税法や税率が存在します。
    </p>

    <h3>国際的な比較</h3>

    <p>各国の消費税の仕組みや税率は大きく異なります。例えば：</p>

    <ol>
      <li>
        <strong>日本：</strong>
        日本では付加価値税（VAT）が導入されており、一般的な税率は2023年現在で10%となっています。
      </li>
      <li>
        <strong>アメリカ：</strong>
        アメリカでは連邦には直接的な消費税は存在せず、各州が独自に消費税を課しています。税率は州によって異なります。
      </li>
      <li>
        <strong>欧州連合（EU）：</strong>
        EUでは加盟国ごとにVATがあり、最低税率が決められていますが、各国はこれを上回る税率を設定することができます。
      </li>
      <li>
        <strong>カナダ：</strong> カナダではGST（Goods and Services
        Tax）とPST（Provincial Sales
        Tax）があり、それぞれの州がPSTを独自に設定しています。
      </li>
    </ol>

    <p>
      これらの例からもわかるように、国や地域によっては連邦と地方の両方で異なる税制が適用されていることがあり、それが複雑な税体系を生み出しています。
    </p>

    <h3>消費税の影響</h3>

    <p>消費税が経済に与える影響は様々です：</p>

    <ol>
      <li>
        <strong>価格上昇：</strong>
        消費税の導入や増税は、商品やサービスの価格上昇を招く可能性があります。これは消費者の購買力に影響を与えることがあります。
      </li>
      <li>
        <strong>経済活動への影響：</strong>
        消費税の変動は経済活動に影響を与える可能性があります。増税が行われると、一時的に購買が減少することが見込まれます。
      </li>
      <li>
        <strong>公共サービスへの影響：</strong>
        消費税収入は政府の主要な財源の一つであり、公共サービスや社会
        プログラムの資金源として利用されます。
      </li>
      <li>
        <strong>産業構造の変化：</strong>
        特定の商品やサービスに高い税率を課すことは、産業構造の変化を促す可能性があります。
      </li>
    </ol>

    <p>
      これらの影響は、税制改革や経済政策の変化によっても変動することがあります。
    </p>

    <h3>まとめ</h3>

    <p>
      消費税は広く採用されている税制の一つであり、商品やサービスの取引に対して一定の割合で課税されます。国や地域によって異なる税法や税率が存在し、それが経済や社会に与える影響も多岐にわたります。政府の収入確保や公共サービスの資金調達にとどまらず、経済全体の調整や産業構造の変化にも関与しています。消費者や事業者、政府といった各主体が影響を受ける中で、持続可能な税制の構築が求められています。
    </p>
  </div>
</template>

<script>
import Calculation from "@/components/Calculation.vue";
import getTitle from "@/getTitle";
import ArticleText from "@/components/ArticleText.vue";
import articles from "@/mixins/articles.js";

export default {
  components: {
    Calculation,
    ArticleText,
  },
  mixins: [getTitle, articles],
  data() {
    return {
      num1: 1000,
      num2: 10,
      formList: [
        { label: "金額", data: "num1" },
        { label: "税率", data: "num2" },
      ],
    };
  },
  computed: {
    total: function () {
      let total = this.num1 * (this.num2 / 100 + 1);
      return Number(this.isNan(total, 0)).toLocaleString();
    },
    tax: function () {
      return (
        Number(this.total.replace(/,/g, "")) - this.num1
      ).toLocaleString();
    },
  },
};
</script>
