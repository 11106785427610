<template>
  <div class="calculation">
    <ArticleText :title="title" :desc="desc" :article="money"></ArticleText>

    <form>
      <Calculation
        v-for="list in formList"
        :key="list.label"
        :list="list"
        v-model.number="$data[list.data]"
        :max="10000000000000000"
      ></Calculation>
    </form>

    <table class="table table-bordered datatable width100">
      <caption>
        所得税の速算表
      </caption>
      <tbody>
        <tr>
          <th class="calculation--bold" scope="col" style="width: 62%">
            課税される所得金額
          </th>
          <th class="calculation--bold" scope="col" style="width: 13%">税率</th>
          <th class="calculation--bold" scope="col" style="width: 25%">
            控除額
          </th>
        </tr>
        <tr>
          <th style="text-align: left" scope="row">
            1,000円 から 1,949,000円まで
          </th>
          <td style="text-align: center">5％</td>
          <td style="text-align: right">0円</td>
        </tr>
        <tr>
          <th style="text-align: left" scope="row">
            1,950,000円 から 3,299,000円まで
          </th>
          <td style="text-align: center">10％</td>
          <td style="text-align: right">97,500円</td>
        </tr>
        <tr>
          <th style="text-align: left" scope="row">
            3,300,000円 から 6,949,000円まで
          </th>
          <td style="text-align: center">20％</td>
          <td style="text-align: right">427,500円</td>
        </tr>
        <tr>
          <th style="text-align: left" scope="row">
            6,950,000円 から 8,999,000円まで
          </th>
          <td style="text-align: center">23％</td>
          <td style="text-align: right">636,000円</td>
        </tr>
        <tr>
          <th style="text-align: left" scope="row">
            9,000,000円 から 17,999,000円まで
          </th>
          <td style="text-align: center">33％</td>
          <td style="text-align: right">1,536,000円</td>
        </tr>
        <tr>
          <th style="text-align: left" scope="row">
            18,000,000円 から 39,999,000円まで
          </th>
          <td style="text-align: center">40％</td>
          <td style="text-align: right">2,796,000円</td>
        </tr>
        <tr>
          <th style="text-align: left" scope="row">40,000,000円 以上</th>
          <td style="text-align: center">45％</td>
          <td style="text-align: right">4,796,000円</td>
        </tr>
      </tbody>
    </table>

    <h2>計算結果</h2>
    <table class="table table-striped">
      <tr>
        <td>所得税</td>
        <td>{{ tax }}円</td>
      </tr>
    </table>

    <h2>所得税についての総合的な解説</h2>

    <p>
      所得税は、国や地域の税制において主要な税目の一つであり、個人や法人が得た所得に対して課される税金です。所得税は広く採用され、政府の歳入を確保し、公共サービスや社会プログラムの資金を提供するために使用されます。以下では、所得税の基本的な仕組み、種類、税率の計算方法、影響などについて総合的に解説します。
    </p>

    <h3>所得税の基本的な仕組み</h3>

    <p>
      所得税は、個人や法人が一定期間内に得た所得に対して課される税金です。基本的な仕組みは以下の通りです：
    </p>

    <ol>
      <li>
        <strong>課税対象：</strong>
        所得税の対象となる所得には、給与所得、事業所得、不動産所得、利子や配当などが含まれます。これらの所得は法律に基づいて明確に定義されています。
      </li>
      <li>
        <strong>控除や控除対象：</strong>
        各国の所得税法では、控除や控除対象が定められており、これによって納税者が一定の経費や支出を差し引いた所得が課税対象となります。
      </li>
      <li>
        <strong>税率：</strong>
        所得税は、課税対象の所得に応じて異なる税率が適用されます。通常、高い所得者ほど高い税率が適用されます。また、低所得者には低い税率や控除が適用されることがあります。
      </li>
      <li>
        <strong>申告と納税：</strong>
        納税者は一般に毎年、前年度の所得に関する確定申告を行います。課税対象の所得や控除、控除対象を適用して、最終的な課税所得を計算し、その額に対応する所得税を納付します。
      </li>
    </ol>

    <p>
      これらの仕組みによって、所得税は個人や法人の経済活動に基づいて公平かつ効果的に課税されることが目指されています。
    </p>

    <h3>所得税の種類</h3>

    <p>
      所得税にはいくつかの異なるタイプがあり、主に個人所得税と法人所得税に分類されます。以下にそれぞれの特徴を述べます：
    </p>

    <ol>
      <li>
        <strong>個人所得税：</strong>
        個人が得た所得に対して課される税金であり、給与所得や事業所得、不動産所得、資産所得などが含まれます。税率は通常、所得が高いほど高くなります。
      </li>
      <li>
        <strong>法人所得税：</strong>
        企業が得た利益に対して課される税金であり、法人の事業所得が課税対象となります。法人所得税は、企業の規模や所得によって異なる税率が適用されることがあります。
      </li>
    </ol>

    <p>
      これらの所得税は、各国や地域によって異なる法体系が採用されており、税制改革が行われることもあります。
    </p>

    <h3>所得税の計算方法</h3>

    <p>
      所得税の計算は、対象となる所得に適用される税率に基づいて行われます。一般的な計算方法は以下の通りです：
    </p>

    <ol>
      <li>
        <strong>所得の計算：</strong>
        対象となる所得から、控除や控除対象を差し引いた所得を計算します。
      </li>
      <li>
        <strong>税率の適用：</strong>
        所得に対応する税率を適用して、課税所得を計算します。
      </li>
      <li>
        <strong>控除や償却：</strong>
        計算された課税所得から、さらに特定の支出や償却を差し引いて最終的な課税所得を決定します。
      </li>
      <li>
        <strong>納税：</strong> 最終的な課税所得に対応する所得税を納付します。
      </li>
    </ol>

    <p>
      この計算プロセスは複雑であり、税法や税制度によって異なる要素が影響を与えることがあります。
    </p>

    <h3>所得税の影響</h3>

    <p>所得税は経済や社会にさまざまな影響を与えます：</p>

    <ol>
      <li>
        <strong>所得再分配：</strong>
        所得税は所得の再分配を促進する一因となります。高所得者から徴収された税金が、社会保障や福祉プログラムなどに再投資され、低所得者や社会的に弱い立場の人々に利益をもたらします。
      </li>
      <li>
        <strong>経済効果：</strong>

        所得税の税率や仕組みが経済活動に与える影響は大きいです。税率が高い場合、企業や個人の投資や消費行動が変化する可能性があります。
      </li>
      <li>
        <strong>公共サービスの資金源：</strong>
        所得税は政府の主要な歳入源であり、公共サービスや社会プログラムの資金確保に寄与します。
      </li>
      <li>
        <strong>雇用の影響：</strong>
        所得税の税率が労働者や事業者に対して直接影響を与えるため、雇用の創出や維持にも影響を及ぼす可能性があります。
      </li>
    </ol>

    <p>
      これらの影響は税制の変化や経済状況によって変動し、バランスの取れた税制の構築が求められます。
    </p>

    <h3>まとめ</h3>

    <p>
      所得税は個人や法人が得た所得に対して課される主要な税目であり、公共サービスや社会プログラムの資金確保に寄与します。所得税の仕組みや税率は国や地域によって異なり、税制改革や経済の変化に伴い調整されることがあります。所得税は経済や社会に様々な影響を与え、バランスの取れた税制が重要です。持続可能な発展を目指す中で、公正で透明かつ効果的な所得税制の構築が求められています。
    </p>
  </div>
</template>

<script>
import Calculation from "../../components/Calculation.vue";
import getTitle from "@/getTitle";
import ArticleText from "../../components/ArticleText.vue";
import articles from "@/mixins/articles.js";

export default {
  components: {
    Calculation,
    ArticleText,
  },
  mixins: [getTitle, articles],
  data() {
    return {
      num1: 0,
      formList: [{ label: "所得（円）", data: "num1" }],
    };
  },
  computed: {
    tax: function () {
      let tax = 0;

      if (this.num1 < 1000) {
        return tax;
      } else if (this.num1 >= 1000 && this.num1 <= 1949000) {
        tax = this.num1 * 0.05;
        this.allWhite();
        this.isActiveSelector(2);
      } else if (this.num1 >= 1950000 && this.num1 <= 3299000) {
        tax = this.num1 * 0.1 - 97500;
        this.allWhite();
        this.isActiveSelector(3);
      } else if (this.num1 >= 3300000 && this.num1 <= 6949000) {
        tax = this.num1 * 0.2 - 427500;
        this.allWhite();
        this.isActiveSelector(4);
      } else if (this.num1 >= 6950000 && this.num1 <= 8999000) {
        tax = this.num1 * 0.23 - 636000;
        this.allWhite();
        this.isActiveSelector(5);
      } else if (this.num1 >= 9000000 && this.num1 <= 17999000) {
        tax = this.num1 * 0.33 - 1536000;
        this.allWhite();
        this.isActiveSelector(6);
      } else if (this.num1 >= 18000000 && this.num1 <= 39999000) {
        tax = this.num1 * 0.4 - 2796000;
        this.allWhite();
        this.isActiveSelector(7);
      } else if (this.num1 >= 40000000) {
        tax = this.num1 * 0.45 - 4796000;
        this.allWhite();
        this.isActiveSelector(8);
      }
      tax = Math.floor(tax);
      return tax.toLocaleString();
    },
  },
};
</script>

<style>
.active {
  background-color: rebeccapurple;
}
</style>
