<template>
  <div class="calculation">
    <ArticleText :title="title" :desc="desc" :article="sports"></ArticleText>

    <form>
      <Calculation
        v-for="list in formList"
        :key="list.label"
        :list="list"
        v-model.number="$data[list.data]"
      ></Calculation>
    </form>

    <h2>計算結果</h2>
    <table class="table table-striped">
      <tr>
        <td>BABIP</td>
        <td>{{ babip }}</td>
      </tr>
    </table>

    <h2>BABIP（Batting Average on Balls In Play）について</h2>
    <p>
      BABIP（Batting Average on Balls In
      Play）は、野球において打者が打席でボールに当たり、守備側の守備に影響される範囲内でプレーが行われる場合の打率を示す統計指標です。BABIPは打者の運や守備の影響を評価し、バッターがヒットを打つ際の運による要素を考慮するための指標として利用されています。
    </p>

    <h3>BABIPの計算式</h3>
    <p>
      BABIPは、ヒットとアウト以外の打席結果に対する打率を示します。計算式は以下の通りです。
    </p>

    <p style="text-align: center">
      BABIP = （安打 - 本塁打） / （打数 - 三振 - 本塁打 + 犠飛）
    </p>

    <p>
      この式では、三振や本塁打、犠飛などは打者のボールに対するプレーに影響を与えないため、これらを除外して安打をボールインプレーによるものだけで割っています。
    </p>

    <h3>BABIPの解釈</h3>
    <p>
      通常、打者のBABIPはリーグ平均で約0.300前後とされています。したがって、打者のBABIPがそれを上回る場合、運が好調であると考えられ、下回る場合は逆に運が不調であると解釈されます。ただし、個々の打者にはその特性や実力が影響するため、一概に高いBABIPが必ずしも運の要因だけとは限りません。
    </p>

    <h3>BABIPの用途</h3>
    <p>
      BABIPは打者の運や守備の影響を評価するだけでなく、投手や守備陣の評価にも利用されます。投手の場合、BABIPが高い場合は守備陣のサポートが十分でないか、あるいは投手自体の制球や変化球の質に問題がある可能性があります。
    </p>

    <p>
      野球において、BABIPは選手やチームの実力や運を客観的に評価するための重要な指標であり、選手の成績をより深く理解する手助けとなっています。
    </p>
  </div>
</template>

<script>
import Calculation from "../../../components/Calculation.vue";
import getTitle from "@/getTitle";
import ArticleText from "../../../components/ArticleText.vue";
import articles from "@/mixins/articles.js";

export default {
  components: {
    Calculation,
    ArticleText,
  },
  mixins: [getTitle, articles],
  data() {
    return {
      num1: 0,
      num2: 0,
      num3: 0,
      num4: 0,
      num5: 0,
      formList: [
        { label: "安打", data: "num1" },
        { label: "本塁打", data: "num2" },
        { label: "打数", data: "num3" },
        { label: "三振", data: "num4" },
        { label: "犠飛", data: "num5" },
      ],
    };
  },
  computed: {
    babip: function () {
      let babip =
        (this.num1 - this.num2) /
        (this.num3 - this.num4 - this.num2 + this.num5);
      return this.isNan(babip, 3);
    },
  },
};
</script>
