<template>
  <div class="calculation">
    <ArticleText :title="title" :desc="desc" :article="sports"></ArticleText>

    <form>
      <Calculation
        v-for="list in formList"
        :key="list.label"
        :list="list"
        v-model.number="$data[list.data]"
      ></Calculation>
    </form>

    <h2>計算結果</h2>
    <table class="table table-striped">
      <tr>
        <td>勝率</td>
        <td>{{ win }}</td>
      </tr>
    </table>

    <h2>勝率（Winning Percentage）について</h2>
    <p>
      勝率は、野球においてチームや選手の成績を測るための基本的な統計指標の一つであり、その競技の中心的な評価基準となっています。勝率は主にチームの試合成績を示すために使われ、チームや選手の実力や成功度合いを表す非常に重要な数値です。
    </p>
    <h3>勝率の計算式</h3>
    <p>
      勝率は、チームが勝った試合数を全試合数で割ったものです。計算式は以下の通りです。
    </p>
    <p style="text-align: center">勝率 = 勝利数 / (勝利数 + 敗北数)</p>
    <p>
      たとえば、ある野球チームが100試合中60勝40敗だった場合、そのチームの勝率は60
      / (60 + 40) = 0.6、つまり60%となります。
    </p>
    <h3>勝率の意味</h3>
    <p>
      勝率が高いほど、チームや選手は良い成績を収めていると言えます。一般的に、0.500（50%）を超える勝率は「勝ち越し」で、それ以下は「負け越し」と呼ばれます。0.500の勝率は平均的な成績を示し、それを上回ることが成功と見なされます。
    </p>
    <h3>勝率と戦略</h3>
    <p>
      勝率はチームの戦略や成績評価に大きな影響を与えます。例えば、プレーオフ進出を目指すチームは高い勝率を追求し、逆に下位に沈んでいるチームは選手の交代や戦術の見直しを行い、勝率向上を目指すことが一般的です。
    </p>
    <p>
      個々の試合結果が勝率に影響を与えるため、勝率は日々の試合やシーズンを通じて変動します。この変動を管理し、戦略的に勝率を向上させることが、成功する野球チームや選手にとって不可欠です。
    </p>
    <p>
      野球の勝率は単なる数値だけでなく、戦略や成績の分析において深い意味を持つ指標であり、競技全体の評価や比較において大きな役割を果たしています。
    </p>
  </div>
</template>

<script>
import Calculation from "../../../components/Calculation.vue";
import getTitle from "@/getTitle";
import ArticleText from "../../../components/ArticleText.vue";
import articles from "@/mixins/articles.js";

export default {
  components: {
    Calculation,
    ArticleText,
  },
  mixins: [getTitle, articles],
  data() {
    return {
      num1: 0,
      num2: 0,
      formList: [
        { label: "勝試合数", data: "num1" },
        { label: "敗試合数", data: "num2" },
      ],
    };
  },
  computed: {
    win: function () {
      let win = this.num1 / (this.num1 + this.num2);
      return this.isNan(win, 2);
    },
  },
};
</script>
