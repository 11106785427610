<template>
  <div class="calculation">
    <ArticleText :title="title" :desc="desc" :article="sports"></ArticleText>

    <form>
      <Calculation
        v-for="list in formList"
        :key="list.label"
        :list="list"
        v-model.number="$data[list.data]"
      ></Calculation>
    </form>

    <h2>計算結果</h2>
    <table class="table table-striped">
      <tr v-for="result in resultList" :key="result">
        <td>{{ result }}</td>

        <td v-if="result == '塁打数'">{{ baseHit }}</td>
        <td v-else-if="result == '打数'">{{ bat }}</td>
        <td v-else-if="result == '長打率'">{{ sluggingPercentage }}</td>
      </tr>
    </table>

    <h2>長打率について</h2>
    <p>
      長打率は、野球において打者の打撃力を測る重要な統計指標のひとつです。この数値は、打者がどれだけの長打を打てるかを示し、その攻撃力を数値で表現します。
    </p>
    <p>長打率の計算式は以下の通りです。</p>
    <p style="text-align: center">
      長打率 = (単打 + 2 × 二塁打 + 3 × 三塁打 + 4 × 本塁打) / 打数
    </p>
    <p>
      たとえば、100打数で25本の単打、10本の二塁打、5本の三塁打、そして5本の本塁打があれば、長打率は
      (25 + 2 × 10 + 3 × 5 + 4 × 5) / 100 = 0.600 となります。
    </p>
    <p>
      長打率が高いほど、打者は単なるヒットだけでなく、長打を多く打っていることを示します。この指標は、単打だけでなく、二塁打や本塁打も考慮しているため、打者の本当の打撃力をより正確に示すことができます。
    </p>
    <p>
      長打率は通常、スラッガーと呼ばれるパワーヒッターやホームランを多く打つ選手の打撃力を評価するために用いられます。長打力がある選手は、相手投手にとって脅威であり、チームにとって攻撃の要となります。
    </p>
    <p>
      野球において、打者がどれだけ長打を打てるかは得点を生み出す上で重要な要素であり、その能力を測るのに長打率は有益な指標となっています。
    </p>
  </div>
</template>
<script>
import Calculation from "../../../components/Calculation.vue";
import getTitle from "@/getTitle";
import ArticleText from "../../../components/ArticleText.vue";
import articles from "@/mixins/articles.js";

export default {
  components: {
    Calculation,
    ArticleText,
  },
  mixins: [getTitle, articles],
  data() {
    return {
      bat: 0,
      hit1: 0,
      hit2: 0,
      hit3: 0,
      hit4: 0,
      formList: [
        { label: "打数", data: "bat" },
        { label: "単打数", data: "hit1" },
        { label: "二塁打数", data: "hit2" },
        { label: "三塁打数", data: "hit3" },
        { label: "本塁打数", data: "hit4" },
      ],
      resultList: ["塁打数", "打数", "長打率"],
    };
  },
  computed: {
    baseHit: function () {
      let baseHit = this.hit1 + this.hit2 * 2 + this.hit3 * 3 + this.hit4 * 4;
      return baseHit;
    },
    sluggingPercentage: function () {
      let baseHit = this.baseHit;
      let sluggingPercentage = baseHit / this.bat;
      return this.isNan(sluggingPercentage);
    },
  },
};
</script>
