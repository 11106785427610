<template>
  <div class="calculation">
    <ArticleText :title="title" :desc="desc" :article="health">
      FFMIの指数が大きいほどしっかり、がっちりした筋肉質な見た目ということです。
    </ArticleText>

    <form>
      <Calculation
        v-for="list in formList"
        :key="list.label"
        :list="list"
        :step="list.step"
        :initialValue="list.value"
        v-model.number="$data[list.data]"
      ></Calculation>
    </form>

    <CalculationSelect
      :resultList="resultList"
      :calculationResult="ffmi"
      labelName="FFMI"
    ></CalculationSelect>

    <h2>FFMI（Fat-Free Mass Index）について</h2>

    <p>
      FFMI（Fat-Free Mass
      Index）は、体脂肪を除いた体重を身長の二乗で割り、筋肉の量を評価する指標です。通常のBMI（Body
      Mass
      Index）が全体の体重を評価するのに対して、FFMIは骨や筋肉など、体脂肪以外の組織の量を考慮します。主に体脂肪率を測定したり、筋力トレーニングの進捗を評価するのに用いられます。
    </p>

    <h3>FFMIの計算式</h3>

    <p>FFMIは次のように計算されます。</p>

    <p style="text-align: center">
      FFMI = （体重 - 体脂肪量） / （身長の二乗）
    </p>

    <p>
      ここで、体重は体脂肪を除いた総体重、体脂肪量は全身の体脂肪の量、身長はメートル単位で表されます。
    </p>

    <h3>FFMIの解釈</h3>

    <p>FFMIの値は通常、以下のように解釈されます。</p>

    <ul>
      <li>18.5未満: 低いFFMI（低い筋肉量）</li>
      <li>18.5 - 24.9: 標準範囲のFFMI（適正な筋肉量）</li>
      <li>25 - 29.9: 高いFFMI（高い筋肉量）</li>
      <li>30以上: 非常に高いFFMI（非常に高い筋肉量）</li>
    </ul>

    <p>
      これらの範囲は一般的な指針であり、個々の健康状態や活動レベルによって評価が変わる可能性があります。特に、体脂肪の少ないアスリートや筋力トレーニングを積極的に行う個人が高いFFMIを持つことが一般的です。
    </p>

    <h3>FFMIの利用</h3>

    <p>
      FFMIは健康上の目標やトレーニングの進捗を評価するのに有用です。以下はいくつかの利用例です。
    </p>

    <ol>
      <li>
        <strong>トレーニングプログラムの評価:</strong>
        筋肉量が増加しているかどうかを定量的に評価するために使用されます。
      </li>
      <li>
        <strong>栄養とトレーニングの調整:</strong>
        FFMIをベースに栄養プランやトレーニングプランを調整することで、効果的な体力向上や筋肉の増加を促進することができます。
      </li>
      <li>
        <strong>アスリートのパフォーマンス評価:</strong>
        筋肉量がスポーツのパフォーマンスにどのように影響するかを理解し、アスリートのトレーニングニーズを理解するために使用されます。
      </li>
    </ol>

    <p>
      ただし、FFMIもあくまで一つの指標であり、全体的な健康状態を評価する際には他の指標や医療専門家のアドバイスを総合的に考慮することが重要です。
    </p>

    <h3>制限と考慮事項</h3>

    <p>FFMIにはいくつかの制限と考慮事項があります。</p>

    <ul>
      <li>
        <strong>体組成の正確性:</strong>
        FFMIは体脂肪量の正確な測定が必要であり、測定方法や精度によって結果が異なる可能性があります。
      </li>
      <li>
        <strong>個体差:</strong>
        個々の体格や筋肉の分布によって、同じFFMIでも見た目や機能が異なることがあります。
      </li>
      <li>
        <strong>成長段階:</strong>
        若い年齢や発育期の個人において、通常の範囲から外れることがあります。
      </li>
    </ul>

    <p>
      これらの要因を考慮して、FFMIを総合的な健康評価の一環として利用することが重要です。
    </p>

    <h3>まとめ</h3>

    <p>
      FFMIは筋肉の量を評価する上で有用な指標であり、トレーニングの進捗や健康状態を理解するのに役立ちます。ただし、他の指標と併用し、正確な測定と総合的な評価が行われることが重要です。医療専門家やトレーニングのプロフェッショ
      ナルとの協力により、個々のニーズに合わせた効果的なアプローチを見つけることができます。
    </p>
  </div>
</template>

<script>
import Calculation from "@/components/Calculation.vue";
import CalculationSelect from "@/components/CalculationSelect.vue";
import getTitle from "@/getTitle";
import ArticleText from "@/components/ArticleText.vue";
import articles from "@/mixins/articles.js";

export default {
  components: {
    Calculation,
    ArticleText,
    CalculationSelect,
  },
  mixins: [getTitle, articles],
  data() {
    return {
      num1: 0,
      num2: 0,
      num3: 0,
      formList: [
        { label: "体重kg", data: "num1", step: "0.1" },
        { label: "身長cm", data: "num2", step: "0.1" },
        { label: "体脂肪率", data: "num3", step: "0.1" },
      ],
      resultList: [
        { label: "FFMI" },
        { label: "18未満", text: "平均以下" },
        { label: "18〜19.5", text: "平均" },
        { label: "19.5〜21.0", text: "平均よりも多い" },
        { label: "21.0〜22.5", text: "とても多い" },
        { label: "22.5〜26", text: "アスリート並" },
        { label: "26以上", text: "あなたはドーピングを使用しています。" },
      ],
    };
  },
  computed: {
    ffmi: function () {
      let ffmi =
        (this.num1 * (1 - this.num3 / 100)) / Math.pow(this.num2 / 100, 2);
      let 体脂肪量 = this.num1 * (this.num3 / 100);
      let 除脂肪体重 = this.num1 - 体脂肪量;
      ffmi = 除脂肪体重 / Math.pow(this.num2 / 100, 2);

      if (isNaN(ffmi) || ffmi == "Infinity") {
        return 0;
      } else {
        if (ffmi <= 18) {
          this.allWhite();
          this.isActiveSelector(2);
        } else if (ffmi >= 18.1 && ffmi <= 19.4) {
          this.allWhite();
          this.isActiveSelector(3);
        } else if (ffmi >= 19.5 && ffmi <= 20.9) {
          this.allWhite();
          this.isActiveSelector(4);
        } else if (ffmi >= 21 && ffmi <= 22.4) {
          this.allWhite();
          this.isActiveSelector(5);
        } else if (ffmi >= 22.5 && ffmi <= 25.9) {
          this.allWhite();
          this.isActiveSelector(6);
        } else if (ffmi >= 26) {
          this.allWhite();
          this.isActiveSelector(7);
        }
        return ffmi.toFixed(1);
      }
    },
  },
};
</script>
