<template>
  <div class="calculation">
    <ArticleText :title="title" :desc="desc" :article="sports"></ArticleText>

    <form>
      <Calculation
        v-for="list in formList"
        :key="list.label"
        :list="list"
        v-model.number="$data[list.data]"
      ></Calculation>
    </form>

    <h2>計算結果</h2>
    <table class="table table-striped">
      <tr v-for="result in resultList" :key="result">
        <td>{{ result }}</td>

        <td v-if="result == 'ヒット数'">{{ hitNum }}</td>
        <td v-else-if="result == '打率'">{{ average }}</td>
        <td v-else-if="result == '出塁率'">{{ onBasePercentage }}</td>
        <td v-else-if="result == '長打率'">{{ sluggingPercentage }}</td>
        <td v-else-if="result == 'OPS'">{{ ops }}</td>
      </tr>
    </table>

    <h2>OPS（On-base Plus Slugging）について</h2>
    <p>
      OPS（On-base Plus
      Slugging）は、野球における打者の総合的な打撃能力を示す統計指標です。これは、出塁率（On-base
      Percentage）と長打率（Slugging
      Percentage）の合計を表しており、打者がどれだけ塁に出るかと同時に、どれだけ長打をするかを総合的に評価するための指標となります。
    </p>
    <p>
      出塁率は、打者が安打や四球、死球などで塁に出る確率を示し、長打率は打者がどれだけ長打をするかを示します。OPSはこれら2つの指標を足し合わせることで、打者の総合的な打撃力をより包括的に表現します。計算式は以下の通りです。
    </p>
    <p style="text-align: center">
      \(OPS = \text{出塁率 (OBP)} + \text{長打率 (SLG)}\)
    </p>
    <p>
      高いOPSは、打者が安打や四球で出塁し、かつ長打を多く放っていることを示します。これは攻撃的な打者やクリーンアップヒッターにとって特に重要であり、相手投手に対して強力な脅威となります。
    </p>
    <p>
      OPSは近年、打者の評価において重要な指標として広く認識されています。単一の数字で打者の総合的な打撃力を示すため、スカウトやチームの戦術立案者、ファンなどが簡便に評価するのに役立ちます。
    </p>
    <p>
      なお、OPSにはいくつかの派生形があります。例えば、OPS+は球場の影響やリーグの平均を考慮して打者のパフォーマンスを補正したものです。これにより、異なる球場や異なる時期の成績を比較しやすくなります。
    </p>
    <p>
      野球の現代において、OPSは単なる打率だけではなく、打者の本質的な能力や貢献度をより深く理解する手段として、多くの人々によって活用されています。
    </p>
  </div>
</template>

<script>
import Calculation from "../../../components/Calculation.vue";
import getTitle from "@/getTitle";
import ArticleText from "../../../components/ArticleText.vue";
import articles from "@/mixins/articles.js";

export default {
  components: {
    Calculation,
    ArticleText,
  },
  mixins: [getTitle, articles],
  data() {
    return {
      bat: 0,
      hit1: 0,
      hit2: 0,
      hit3: 0,
      hit4: 0,
      fourDeadBalls: 0,
      sacrificeFly: 0,
      formList: [
        { label: "打数", data: "bat" },
        { label: "単打数", data: "hit1" },
        { label: "二塁打数", data: "hit2" },
        { label: "三塁打数", data: "hit3" },
        { label: "本塁打数", data: "hit4" },
        { label: "四死球数", data: "fourDeadBalls" },
        { label: "犠飛数", data: "sacrificeFly" },
      ],
      resultList: ["ヒット数", "打率", "出塁率", "長打率", "OPS"],
    };
  },
  computed: {
    hitNum: function () {
      return this.hit1 + this.hit2 + this.hit3 + this.hit4;
    },
    average: function () {
      let average = this.hitNum / this.bat;
      return this.isNan(average);
    },
    onBasePercentage: function () {
      let onBasePercentage =
        (this.hitNum + this.fourDeadBalls + this.sacrificeFly) /
        (this.bat + this.fourDeadBalls + this.sacrificeFly);
      return this.isNan(onBasePercentage);
    },
    sluggingPercentage: function () {
      let baseHit = this.hit1 + this.hit2 * 2 + this.hit3 * 3 + this.hit4 * 4;
      let sluggingPercentage = baseHit / this.bat;
      return this.isNan(sluggingPercentage);
    },
    ops: function () {
      let ops = Number(this.onBasePercentage) + Number(this.sluggingPercentage);
      return ops.toFixed(3);
    },
  },
};
</script>
