<template>
  <div class="calculation">
    <ArticleText :title="title" :desc="desc" :article="sports"></ArticleText>

    <form>
      <Calculation
        v-for="list in formList"
        :key="list.label"
        :list="list"
        v-model.number="$data[list.data]"
      ></Calculation>
    </form>

    <h2>計算結果</h2>
    <table class="table table-striped">
      <tr v-for="result in resultList" :key="result">
        <td>{{ result }}</td>

        <td v-if="result == 'ヒット数'">{{ hitNum }}</td>
        <td v-else-if="result == '打率'">{{ average }}</td>
        <td v-else-if="result == '出塁率'">{{ onBasePercentage }}</td>
      </tr>
    </table>

    <h2>打率（Batting Average）と出塁率（On-base Percentage）について</h2>
    <p>
      打率と出塁率は、野球において打者の能力や効果を測るための基本的な統計指標であり、選手の打撃力や出塁能力を評価するのに役立ちます。
    </p>
    <h3>打率</h3>
    <p>
      打率は、打者がヒットを打つ確率を示す指標です。具体的には、打者の安打数を打数で割ったもので、小数点第三位まで表現されます。計算式は以下の通りです。
    </p>
    <p style="text-align: center">打率 = 安打数 / 打数</p>
    <p>
      たとえば、100打数で25本のヒットがあれば、打率は 25 / 100 = 0.250
      となります。高い打率は、打者がボールに当たってヒットを生み出す能力を示します。
    </p>
    <h3>出塁率</h3>
    <p>
      出塁率は、打者がベースに出る確率を示す指標で、四球、死球、安打などにより出塁した回数を打数と出塁死数の合計で割ったものです。計算式は以下の通りです。
    </p>
    <p style="text-align: center">
      出塁率 = (安打 + 四球 + 死球) / (打数 + 四球 + 死球 + 犠飛)
    </p>
    <p>
      出塁率は打率よりも広い意味での出塁を考慮するため、単なるヒットだけでなく四球や死球も含まれます。これにより、チームにとって価値のある出塁がどれだけあるかを示します。例えば、100打数で25本のヒットに加えて10四球、5死球があれば、出塁率は
      (25 + 10 + 5) / (100 + 10 + 5 + 0) = 0.400 となります。
    </p>
    <p>
      打率と出塁率は打者の能力を多面的に評価する上で重要な要素であり、バランスよくこれらの指標を高めることが、強力な打者としての評価につながります。
    </p>
  </div>
</template>

<script>
import Calculation from "../../../components/Calculation.vue";
import getTitle from "@/getTitle";
import ArticleText from "../../../components/ArticleText.vue";
import articles from "@/mixins/articles.js";

export default {
  components: {
    Calculation,
    ArticleText,
  },
  mixins: [getTitle, articles],
  data() {
    return {
      bat: 0,
      hit1: 0,
      hit2: 0,
      hit3: 0,
      hit4: 0,
      fourDeadBalls: 0,
      sacrificeFly: 0,
      formList: [
        { label: "打数", data: "bat" },
        { label: "単打数", data: "hit1" },
        { label: "二塁打数", data: "hit2" },
        { label: "三塁打数", data: "hit3" },
        { label: "本塁打数", data: "hit4" },
        { label: "四死球数", data: "fourDeadBalls" },
        { label: "犠飛数", data: "sacrificeFly" },
      ],
      resultList: ["ヒット数", "打率", "出塁率"],
    };
  },
  computed: {
    average: function () {
      let average = this.hitNum / this.bat;

      if (isNaN(average)) {
        return 0;
      } else {
        return average.toFixed(3);
      }
    },
    hitNum: function () {
      return this.hit1 + this.hit2 + this.hit3 + this.hit4;
    },
    onBasePercentage: function () {
      let onBasePercentage =
        (this.hitNum + this.fourDeadBalls + this.sacrificeFly) /
        (this.bat + this.fourDeadBalls + this.sacrificeFly);
      return this.isNan(onBasePercentage);
    },
  },
};
</script>
