<template>
  <div class="calculation">
    <ArticleText :title="title" :desc="desc" :article="health">
      <p>
        ボディマス指数とも呼ばれ、体重と身長から算出される肥満度を表す体格指数です。子供には別の指数である<router-link
          to="/health/laurel"
          >ローレル指数</router-link
        >が存在します。
      </p>
      <p>
        成人はBMIが国際的な指標として用いられます。健康を維持するには、日頃からBMIを把握することが大切です。
      </p>
    </ArticleText>

    <form>
      <Calculation
        v-for="list in formList"
        :key="list.label"
        :list="list"
        :step="list.step"
        :initialValue="list.value"
        v-model.number="$data[list.data]"
      ></Calculation>
    </form>

    <CalculationSelect
      :resultList="resultList"
      :calculationResult="bmi"
      labelName="BMI"
    >
      <p>
        あなたの適正体重は<span class="calculation-select__result"
          >{{ weight }}kg</span
        >です。
      </p>
    </CalculationSelect>

    <h2>身体質量指数（BMI）についての詳細な説明</h2>

    <p>
      身体質量指数（Body Mass Index,
      BMI）は、体重と身長の比率を示す数値で、個々の身体の肥満度を評価するための一般的な指標です。BMIは、健康上のリスクを評価するため、医療や栄養学、公衆衛生などの分野で幅広く利用されています。BMIは、数値の範囲に基づいて身体の重量を分類し、その値をもとに健康リスクの判断が行われます。
    </p>

    <h3>BMIの計算式</h3>
    <p>
      BMIは、体重（kg）を身長（m）の二乗で割ることで計算されます。計算式は以下の通りです。
    </p>

    <p style="text-align: center">BMI = 体重（kg） / (身長（m） × 身長（m）)</p>

    <p>
      例えば、体重70kg、身長170cmの場合、身長をメートル単位に換算してBMIは70 /
      (1.7 × 1.7) ≈ 24.22となります。
    </p>

    <h3>BMIの分類</h3>
    <p>
      BMIの数値に基づいて、以下のように分類が行われます。ただし、これらの範囲は一般的な指針であり、個々の健康状態や体格、筋肉量などを総合的に考慮する必要があります。
    </p>

    <ul>
      <li>18.5未満: 低体重（低体重、やせ型）</li>
      <li>18.5 - 24.9: 標準体重（正常体重、健康的な体重）</li>
      <li>25 - 29.9: 肥満度1度（過体重、軽度の肥満）</li>
      <li>30 - 34.9: 肥満度2度（中度の肥満）</li>
      <li>35 - 39.9: 肥満度3度（重度の肥満）</li>
      <li>40以上: 肥満度4度（極度の肥満、高度な肥満）</li>
    </ul>

    <p>
      標準体重範囲にある人々が一般的に健康であると考えられますが、BMIだけでは全ての健康リスクを評価することは難しいため、他の指標や詳細な健康評価が必要です。
    </p>

    <h3>BMIの制約と批判</h3>
    <p>
      BMIは一般的に広く利用されていますが、いくつかの制約や批判が存在します。例えば、BMIは身長と体重の比率しか考慮しておらず、体脂肪率や筋肉量、分布などを反映していません。そのため、筋肉が発達しているアスリートや高齢者の場合、BMIだけで肥満度を正確に判断するのは難しいことがあります。
    </p>

    <p>
      また、BMIは人種や地域差に対応していないため、異なる人種や地域の個々の健康リスクを正確に評価するのは難しいとされています。このため、BMIを利用する際には他の健康指標や医療専門家のアドバイスを併用することが重要です。
    </p>

    <h3>健康リスクとの関連</h3>
    <p>
      BMIが高すぎる場合や低すぎる場合、健康リスクが増加する可能性があります。肥満は糖尿病、心臓病、高血圧、特定のがんなどと関連しています。一方、低体重は骨粗鬆症、貧血、免疫機能の低下などのリスクを引き起こす可能性があります。健康上の問題を抱えている場合は、BMIを参考にしながらも、総合的な健康評価を行うことが大切です。
    </p>

    <p>
      このように、BMIは健康リスクを評価する手段の一つとして利用されますが、個々の特性や健康状態を正確に評価するためには他の指標と併用し、医療専門家の指導を受けることが重要です。
    </p>
  </div>
</template>

<script>
import Calculation from "@/components/Calculation.vue";
import CalculationSelect from "@/components/CalculationSelect.vue";
import getTitle from "@/getTitle";
import ArticleText from "@/components/ArticleText.vue";
import articles from "@/mixins/articles.js";

export default {
  components: {
    Calculation,
    ArticleText,
    CalculationSelect,
  },
  mixins: [getTitle, articles],
  data() {
    return {
      num1: Number,
      num2: Number,
      formList: [
        { label: "体重kg", data: "num1", step: "0.1" },
        { label: "身長cm", data: "num2", step: "0.1" },
      ],
      resultList: [
        { label: "BMI" },
        { label: "18.5未満", text: "やせている" },
        { label: "18.5〜25未満", text: "普通" },
        { label: "25〜30未満", text: "肥満(1度)" },
        { label: "30〜35未満", text: "肥満(2度)" },
        { label: "35〜40未満", text: "肥満(3度)" },
        { label: "40以上	", text: "肥満(4度)" },
      ],
    };
  },
  computed: {
    bmi: function () {
      let bmi = this.num1 / Math.pow(this.num2 / 100, 2);

      if (isNaN(bmi) || bmi == "Infinity") {
        return 0;
      } else {
        if (bmi <= 18.4) {
          this.allWhite();
          this.isActiveSelector(2);
        } else if (bmi >= 18.5 && bmi <= 24.9) {
          console.log(989898);
          this.allWhite();
          this.isActiveSelector(3);
        } else if (bmi >= 25 && bmi <= 29.9) {
          this.allWhite();
          this.isActiveSelector(4);
        } else if (bmi >= 30 && bmi <= 34.9) {
          this.allWhite();
          this.isActiveSelector(5);
        } else if (bmi >= 35 && bmi <= 39.9) {
          this.allWhite();
          this.isActiveSelector(6);
        } else if (bmi >= 40) {
          this.allWhite();
          this.isActiveSelector(7);
        }

        return bmi.toFixed(1);
      }
    },
    weight: function () {
      return (Math.pow(this.num2 / 100, 2) * 22).toFixed(1);
    },
  },
};
</script>
