<template>
  <div class="calculation">
    <ArticleText :title="title" :desc="desc" :article="sports"></ArticleText>

    <form>
      <Calculation
        v-for="list in formList"
        :key="list.label"
        :list="list"
        v-model.number="$data[list.data]"
      ></Calculation>
    </form>

    <h2>計算結果</h2>
    <table class="table table-striped">
      <tr>
        <td>QS率</td>
        <td>{{ qs }}%</td>
      </tr>
    </table>
    <h2>QS率（Quality Start Rate）について</h2>
    <p>
      QS率（Quality Start
      Rate）は、野球において投手の試合での出来栄えを測る指標の一つです。Quality
      Start（QS）は、投手が試合で最低6イニングを投げ、自責点が3点以下である場合に与えられる統計的な成功基準です。QS率は、投手がQuality
      Startを達成した試合の割合を示し、投手の安定性や試合ごとの成功度合いを評価するのに利用されます。
    </p>
    <h3>QS率の計算式</h3>
    <p>
      QS率は、Quality
      Startを達成した試合数を投手が登板した試合数で割ったものです。計算式は以下の通りです。
    </p>
    <p style="text-align: center">
      QS率 = Quality Startを達成した試合数 / 投手が登板した試合数
    </p>
    <p>
      たとえば、投手が20試合に登板し、そのうち15試合がQuality
      Startだった場合、QS率は15 / 20 = 0.75、つまり75%となります。
    </p>
    <h3>QS率の意味</h3>
    <p>
      QS率が高いほど、投手は試合ごとに一定の安定感を持ってQuality
      Startを達成していることを示します。Quality
      Startは投手が相手打者に対して一定の制御を持っていることを表し、QS率が高い投手は信頼性のある先発投手とされます。
    </p>
    <h3>QS率の利用</h3>
    <p>
      QS率は投手の安定性を示すだけでなく、チームの成功にも大きく影響します。QSを達成する投手が多いチームは、投手陣の信頼性があり、リリーフ陣に対する負担も軽減されるため、勝利に結びつきやすくなります。
    </p>
    <p>
      野球において、QS率は投手陣の評価や戦術の立案において有益な指標となっています。これは単なる勝利数や自責点平均だけでは評価しきれない、投手の試合ごとのパフォーマンスをより詳細に把握する手段として重要視されています。
    </p>
  </div>
</template>

<script>
import Calculation from "../../../components/Calculation.vue";
import getTitle from "@/getTitle";
import ArticleText from "../../../components/ArticleText.vue";
import articles from "@/mixins/articles.js";

export default {
  components: {
    Calculation,
    ArticleText,
  },
  mixins: [getTitle, articles],
  data() {
    return {
      num1: 0,
      num2: 0,
      formList: [
        { label: "QS数", data: "num1" },
        { label: "先発登板数", data: "num2" },
      ],
    };
  },
  computed: {
    qs: function () {
      let qs = (this.num1 / this.num2) * 100;
      return this.isNan(qs, 1);
    },
  },
};
</script>
