<template>
  <div>
    <Search v-model="keyword"></Search>
    <nav v-show="!keyword">
      <ul>
        <template v-for="nav in navList">
          <li
            v-if="nav.category == 'スポーツ'"
            class="is-select"
            :key="nav.id"
            @click="noSelect(), selectCategory(nav.category, $event)"
          >
            {{ nav.category }}
          </li>
          <li
            v-else
            :key="nav.category"
            @click="noSelect(), selectCategory(nav.category, $event)"
          >
            {{ nav.category }}
          </li>
        </template>
      </ul>
    </nav>

    <div class="article-list">
      <p v-if="isNoneSearchArticle && this.keyword">
        キーワードに一致する記事はありません。
      </p>

      <template v-else-if="category == 'スポーツ' && isNoneSearchArticle">
        <router-link
          class="card"
          v-for="article in sports"
          :to="article.url"
          :key="article.title"
        >
          <Article :article="article"></Article>
        </router-link>
      </template>

      <template v-else-if="category == '健康' && isNoneSearchArticle">
        <router-link
          class="card"
          v-for="article in health"
          :to="article.url"
          :key="article.title"
        >
          <Article :article="article"></Article>
        </router-link>
      </template>

      <template v-else-if="category == 'お金' && isNoneSearchArticle">
        <router-link
          class="card"
          v-for="article in money"
          :to="article.url"
          :key="article.title"
        >
          <Article :article="article"></Article>
        </router-link>
      </template>

      <template v-else-if="category == '数学・算数' && isNoneSearchArticle">
        <router-link
          class="card"
          v-for="article in arithmetic"
          :to="article.url"
          :key="article.title"
        >
          <Article :article="article"></Article>
        </router-link>
      </template>

      <template v-else-if="category == '科学' && isNoneSearchArticle">
        <router-link
          class="card"
          v-for="article in science"
          :to="article.url"
          :key="article.title"
        >
          <Article :article="article"></Article>
        </router-link>
      </template>

      <template v-else-if="category == '日付' && isNoneSearchArticle">
        <router-link
          class="card"
          v-for="article in date"
          :to="article.url"
          :key="article.title"
        >
          <Article :article="article"></Article>
        </router-link>
      </template>

      <template v-else-if="searchArticle.length != 0">
        <router-link
          class="card"
          v-for="article in searchArticle"
          :to="article.url"
          :key="article.title"
        >
          <Article :article="article"></Article>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import articles from "@/mixins/articles.js";
import Article from "../components/Article.vue";
import Search from "../components/Search.vue";

export default {
  mixins: [articles],
  components: {
    Article,
    Search,
  },
  methods: {
    selectCategory: function (category, event) {
      this.category = category;
      event.target.classList.add("is-select");
    },
    noSelect: function () {
      let isSleect = document.querySelector(".is-select");
      if (isSleect) {
        document.querySelector(".is-select").classList.remove("is-select");
      }
    },
  },
  computed: {
    isNoneSearchArticle: function () {
      return this.searchArticle.length === 0;
    },
  },
  data() {
    return {
      category: "スポーツ",
      navList: [
        { id: 1, category: "スポーツ" },
        { id: 2, category: "健康" },
        { id: 3, category: "お金" },
        // { id: 4, category: '数学・算数' },
        // { id: 5, category: '科学' },
        { id: 6, category: "日付" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.is-select {
  border-bottom: 2px solid $mainColor;
  color: $mainColor;
}
ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 20px;
}
li {
  list-style: none;
  width: calc(100% / 3);
  text-align: center;
  padding: 4px 0;
  border: 1px solid $bodyColor;
  font-weight: bold;
  @include linkColor;
  background-color: #ffffff;
}
@media screen and (max-width: 580px) {
  li {
    font-size: 14px;
  }
}
</style>
